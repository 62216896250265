html {
  touch-action: manipulation;
}

:root:root {
  --adm-color-primary: #346de4;
  --adm-color-success: #00b578;
  --adm-color-warning: #ff8f1f;
  --adm-color-danger: #ff3141;
  --adm-color-white: #fff;
  --adm-color-text: #333;
  --adm-color-text-secondary: #666;
  --adm-color-weak: #999;
  --adm-color-light: #ccc;
  --adm-color-border: #eee;
  --adm-color-box: #f5f5f5;
  --adm-color-background: #fff;
  --adm-font-size-main: var(--adm-font-size-5);
  --adm-font-family: -apple-system, blinkmacsystemfont, 'Helvetica Neue', helvetica, segoe ui, arial,
    roboto, 'PingFang SC', 'miui', 'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
}

.adm-toast-mask .adm-toast-main {
  background-color: #000 !important;
  opacity: 0.7 !important;
}
